@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.footer {
  font-family: 'Roboto', sans-serif;
  background-color: #011622;
  color: #fff;
  text-align: center;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;
}

.footer p {
  margin: 10px 0;
  color: #fff;
}

.footer a {
  color: #aac8da;
  text-decoration: none;
}

.footer a:hover {
  color: #fff;
}
