@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #0277bd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  text-align: center;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero h1 {
  color: #fff;
}

.hero-text-container p {
  color: #fff;
}

.hero-text-container {
  margin-top: 20px;
  width: 50%;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2,
p {
  margin: 10px 0;
}

.sample-text {
  color: #1da1f2;
  font-size: 1.55rem;
  overflow-wrap: break-word;
  max-height: 100px;
  overflow-y: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 50px;
}

.code-container {
  position: relative;
  margin: 10px 0;
  background-color: #f0f0f0;
}

code {
  display: block;
  padding: 10px;
  border-radius: 4px;
  color: #0d47a1;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-container {
  display: flex;
  justify-content: center;
}

button {
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1565c0;
}

.intro-text {
  margin: 0 0 20px 0;
  color: #b3e5fc;
}
